.introText {
    margin: 120px 60px 60px 60px;
}

.impactsMetrics {
    margin-top: -60px;
}

.impactCard {}

.impactCardImg {
    margin: 0 -10px 0 -60px
}

.confection {
    margin-top: 120px;
    position: relative;
    padding-bottom: 120px;
    max-height: 1500px;
    min-height: 1200px;
    overflow: hidden;
}

.introConfectionHolder {
    position: absolute;
    top: 0;
}

.introConfection {
    width: 40%;
    margin: 120px 60px;
    background: white;
    padding: 30px;
}

.introConfectionTable {
    width: 60%;
    padding: 20px;
    margin: 20% 60px 0;
    background: #c3d82e;
    float: right;
}

.introConfectionTableYear {
    background: black;
    display: block;
    width: fit-content;
    padding: 5px 20px;
    color: #c3d82e;
}

.introConfectionTableTitle {
    text-align: center;
    font-size: 120px;
    line-height: 60%;
    margin-top: -20px;
    margin-bottom: 40px;
}

.introConfectionTableTitleSpan {
    font-size: 30px;
    display: block;
}

.introConfectionTable p {
    padding: 20px;
    text-align: center;
}

.introConfectionTable div:nth-child(2) {
    border-left: 1px solid black;
    border-right: 1px solid black;
}

@media (max-width: 1000px) {
    .introConfectionTable,
    .introConfection {
        width: 100%;
        padding: 30px;
        margin: 120px 60px;
        float: none;
    }
    .introConfectionTable div:nth-child(2) {
        border: none;
    }
    .introConfectionTableTitle {
        font-size: 40px;
        line-height: 100%;
    }
    .introConfectionTableColumns {
        grid-template-columns: 1fr !important
    }
    .introConfectionTable p:first-child {
        font-weight: bolder;
    }
    .introConfectionTable p {
        padding: 5px 0;
    }
}

@media (max-width: 600px) {
    .introConfectionTable,
    .introConfection {
        width: 100%;
        padding: 30px;
        margin: 120px 60px;
        float: none;
    }
    .introConfectionTable div:nth-child(2) {
        border: none;
    }
    .introConfectionTableYear {
        background-blend-mode: color-dodge;
    }
}